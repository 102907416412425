/* eslint-disable @next/next/no-img-element */
import PropTypes from 'prop-types';

const badgeType = {
    normal: 'hidden',
    badge: 'px-2 py-0.5 -top-2 border-2 border-white',
    dot: 'w-1.5 h-1.5 rounded-full -indent-40 mt-2.5 mr-5 -top-2',
};

const avatarShape = {
    normal: 'rounded-none',
    square: 'rounded-lg',
    circle: 'rounded-full',
};

const avatarSize = {
    small: 'w-6 h6 small',
    medium: 'w-8 h-8 medium',
    large: 'w-12 h-12 large',
    extraLarge: 'w-16 h-16',
};

const Avatar = ({
    className,
    badge,
    shape,
    size,
    imgSrc,
    title,
    onClick,
    badgeTxt,
}) => {
    return (
        <div
            shape={shape}
            size={size}
            className={`relative ${className} ${avatarSize[size]}`}
        >
            <div
                className={`flex justify-center items-center text-center bg-primary-300 overflow-hidden text-white text-xl ${avatarShape[shape]}`}
            >
                {badgeTxt && (
                    <div
                        className={`absolute bg-error-100 text-white text-xs flex items-center justify-center overflow-hidden rounded-xl ${
                            badgeType[badge]
                        } ${avatarSize[size] === avatarSize['small'] && 'py-0 -right-6'}  ${
                            avatarSize[size] === avatarSize['medium'] &&
                            '-right-5'
                        } ${avatarSize[size] === avatarSize['large'] && '-right-4'} ${
                            avatarSize[size] === avatarSize['extraLarge'] &&
                            '-right-2'
                        } z-10`}
                    >
                        {badgeTxt}
                    </div>
                )}
                {imgSrc ? (
                    <img
                        onClick={onClick}
                        src={imgSrc}
                        width="32"
                        height="32"
                        alt="icon"
                        className={`flex-shrink-0 object-cover ${avatarSize[size]}`}
                    />
                ) : (
                    <span className="text-xl font-medium text-primary-900">
                        {title}
                    </span>
                )}
            </div>
        </div>
    );
};

Avatar.defaultProps = {
    className: '',
    badge: 'normal',
    shape: 'circle',
    size: 'medium',
    imgSrc: '/images/profile_1.svg',
    title: '',
    onClick: () => {},
    badgeTxt: '',
};

Avatar.propTypes = {
    className: PropTypes.string,
    badge: PropTypes.oneOf(['normal', 'badge', 'dot']),
    shape: PropTypes.oneOf(['normal', 'square', 'circle']),
    size: PropTypes.oneOf(['small', 'medium', 'large', 'extraLarge']),
    imgSrc: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    badgeTxt: PropTypes.string,
};

export default Avatar;

import PropTypes from 'prop-types';
import NextImage from 'next/image';
import { useRef, useState, useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import 'swiper/css/autoplay';
import {
    FreeMode,
    Navigation,
    Pagination,
    Thumbs,
    Autoplay,
} from 'swiper/modules';
import { Swiper } from 'swiper/react';

const Slider = ({
    children,
    loop = true,
    slidesPerView,
    spaceBetween,
    rows,
    breakpoints,
    thumbsSwiper,
    freeMode,
    watchSlidesProgress,
    showPagination = true,
    setThumbsSwiper = () => {},
    style,
    ...rest
}) => {
    const swiperRef = useRef(null);
    const [showArrows, setShowArrows] = useState(false);

    useEffect(() => {
        const updateShowArrows = () => {
            if (swiperRef.current && swiperRef.current.swiper) {
                const swiper = swiperRef.current.swiper;
                setShowArrows(
                    swiper.slides.length > swiper.params.slidesPerView
                );
            }
        };

        if (swiperRef.current && swiperRef.current.swiper) {
            updateShowArrows();
        }

        if (swiperRef?.current) {
            swiperRef?.current?.swiper?.on('init resize', updateShowArrows);
        }

        return () => {
            if (swiperRef?.current) {
                swiperRef?.current?.swiper?.off(
                    'init resize',
                    updateShowArrows
                );
            }
        };
    }, [slidesPerView]);

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    return (
        <Swiper
            ref={swiperRef}
            thumbs={{ swiper: thumbsSwiper }}
            onSwiper={setThumbsSwiper}
            pagination={{
                dynamicBullets: true,
                clickable: true,
            }}
            loop={loop}
            modules={[FreeMode, Navigation, Thumbs, Pagination, Autoplay]}
            grid={{ rows: rows, fill: 'row' }}
            slidesPerView={slidesPerView}
            spaceBetween={spaceBetween}
            breakpoints={breakpoints}
            freeMode={freeMode}
            watchSlidesProgress={watchSlidesProgress}
            className="mySwiper"
            style={{
                '--swiper-pagination-color': '#9D5CF3',
                '--swiper-pagination-bullet-inactive-color': '#F1E8FD',
                '--swiper-pagination-bullet-inactive-opacity': '1',
                '--swiper-pagination-bullet-size': '12px',
                '--swiper-pagination-bullet-horizontal-gap': '6px',
                ...style,
            }}
            {...rest}
        >
            {children}
            {showPagination && (
                <div className="absolute bottom-[14px] left-1/2 -translate-x-1/2 flex w-[188px]">
                    <div className="flex justify-between w-full absolute top-1/2 transform -translate-y-1/2">
                        {showArrows && (
                            <>
                                <div
                                    className="cursor-pointer"
                                    onClick={goPrev}
                                >
                                    <NextImage
                                        src="/images/icons/icon_arrow_blue_left.svg"
                                        alt="arrow prev"
                                        width={18}
                                        height={18}
                                    />
                                </div>
                                <div
                                    className="cursor-pointer"
                                    onClick={goNext}
                                >
                                    <NextImage
                                        src="/images/icons/icon_arrow_blue_right.svg"
                                        alt="arrow next"
                                        width={18}
                                        height={18}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </Swiper>
    );
};

Slider.propTypes = {
    children: PropTypes.node.isRequired,
    loop: PropTypes.bool,
    slidesPerView: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    spaceBetween: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rows: PropTypes.number,
    breakpoints: PropTypes.object,
    style: PropTypes.object,
    thumbsSwiper: PropTypes.any,
    freeMode: PropTypes.bool,
    watchSlidesProgress: PropTypes.bool,
    showPagination: PropTypes.bool,
    setThumbsSwiper: PropTypes.func,
};

Slider.defaultProps = {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    rows: 1,
    breakpoints: {},
    style: {},
};

export default Slider;
